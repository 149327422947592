/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DateDialog from '@/components/EdDate/Index';
export default {
  name: 'Attendance',
  data: function data() {
    return {
      showConfirm: false,
      endDate: '',
      currDate: '',
      currDates: '',
      quanqin: 0,
      chidao: 0,
      zaotui: 0,
      kugong: 0,
      jiaban: 0,
      qingjia: 0,
      monthShow: false,
      weeksidx: 0,
      userNums: 0
    };
  },
  components: {
    DateDialog: DateDialog
  },
  mounted: function mounted() {
    this.$route.query.list;
    this.currDate = this.$route.query.currDate;
    this.currDates = this.$route.query.currDates;
    this.endDate = this.$route.query.endDate;
    this.weeksidx = this.$route.query.weeksidx;
    this.getSelectDepAll();
    this.getSelectUserNums();
  },
  methods: {
    getSelectUserNums: function getSelectUserNums() {
      var _this = this;

      this.$api.Attence.getSelectUserNums({
        depSerial: this.$route.query.list,
        data: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        var code = response.code,
            data = response.data;

        if (+code === _this.$code.success) {
          _this.userNums = data.userNums;
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    weeks: function weeks(i) {
      this.weeksidx = i;

      if (i == 1) {
        this.monthShow = true;
      } else {
        this.monthShow = false;
      }

      this.setDate(i, 1);
    },
    setDate: function setDate(idx, i) {
      if (this.weeksidx == 0) {
        var thisDate = '';

        if (i != 1) {
          if (idx == 1) {
            thisDate = this.$utils.DateUtils.beforesunday(this.currDate, -7);
          } else {
            thisDate = this.$utils.DateUtils.beforesunday(this.currDate, 7);
          }
        } else {
          thisDate = this.currDate;
        }

        var kong = this.$utils.DateUtils.getWeekTimeStamp(thisDate);
        this.currDate = this.$utils.DateUtils.lastMonday(thisDate, kong);
        this.endDate = this.$utils.DateUtils.lastsunday(thisDate, kong);
      } else {
        if (i != 1) {
          if (idx == 1) {
            this.dateidx--;
            this.currDates = this.$utils.DateUtils.getonMonth(this.currDates);
          } else {
            this.dateidx++;
            this.currDates = this.$utils.DateUtils.gettheMonth(this.currDates);
          }
        }

        this.currDates = this.$utils.DateUtils.getYYYYMM(this.currDates);
      }

      this.getSelectDepAll();
    },
    onConfirm: function onConfirm(ref) {
      ref = this.$utils.Tools.repairDate(ref);
      if (ref == '') return;
      this.currDate = ref;
      this.setDate(this.weeksidx, 1);
    },
    goDetails: function goDetails(text, idx, num) {
      if (num === 0) {
        this.$utils.Tools.toastShow("\u6682\u65E0".concat(text, "\u6570\u636E"));
        return;
      }

      this.$router.push({
        path: '/AttendanceDetail',
        query: {
          text: text,
          idx: idx,
          list: this.$route.query.list,
          currDate: this.currDate,
          currDates: this.currDates,
          endDate: this.endDate,
          weeksidx: this.weeksidx
        }
      });
    },
    getSelectDepAll: function getSelectDepAll() {
      var _this2 = this;

      this.$api.Attence.getSelectDepAll({
        depSerial: this.$route.query.list,
        data: this.$utils.Store.getItem('userSerial'),
        end: this.weeksidx == 1 ? this.currDates + '-30' + ' 23:59:59' : this.endDate + ' 23:59:59',
        start: this.weeksidx == 1 ? this.currDates + '-01' + ' 00:00:00' : this.currDate + ' 00:00:00'
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this2.$utils.Tools.loadHide();

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.quanqin = data.quanqin;
          _this2.chidao = data.chidao;
          _this2.zaotui = data.zaotui;
          _this2.kugong = data.kugong;
          _this2.jiaban = data.jiaban;
          _this2.qingjia = data.qingjia;
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    }
  }
};